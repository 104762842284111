const loadingStyle = {
  marginLeft: "auto",
  marginRight: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center"
};

const Loader = () => {
  return (
    <div style={loadingStyle}>
      Redirecting...      
    </div>
  );
};

export default Loader;