export const CategoryFarmGroup = {
  YB: "Bulls",
  S: "Steers",
  H: "Heifers",
} as const;

export const DateRangeFarmGroup = [
  {
    label: "Last Kill date",
    id: "LastKillDate",
  },
  {
    label: "Last 12 months",
    id: "TwelveMonths",
  },
];

export const CategoryFarmGroupArray = Object.entries(CategoryFarmGroup).map(([id, label]) => ({
  id,
  label,
}));
