import { lazy } from "react";

const OidcCallback = lazy(() => import("components/oidc/OidcCallback"));
const OidcSilentCallback = lazy(() => import("components/oidc/OidcSilentCallback"));
const NotAuthenticated = lazy(() => import("components/oidc/NotAuthentication"));
const AutoLogin = lazy(() => import("components/oidc/AutoLogin"));
const NotFound404 = lazy(() => import("pages/error/NotFound404"));
const ActiveUser = lazy(() => import("pages/active-user/ActiveUser"));

const authRoutes: Array<{
  path: string;
  name?: string;
  component: any;
  title?: string;
  exact?: boolean;
  useAuthLayout?: boolean;
  isSettingMenu?: boolean;
}> = [
  {
    path: "/auth/callback",
    component: OidcCallback,
    title: "OidcCallback",
  },
  {
    path: "/auth/silent_callback",
    component: OidcSilentCallback,
    title: "OidcSilentCallback",
  },
  {
    path: "/auth/401",
    component: NotAuthenticated,
    title: "NotAuthenticated",
  },
  {
    path: "/auth/autologin",
    component: AutoLogin,
    title: "AutoLogin",
  },
  {
    path: "/change-password",
    component: ActiveUser,
    title: "ActiveUser",
  },
  {
    path: "/404",
    name: "404",
    component: NotFound404,
    useAuthLayout: false,
  },
];
export { authRoutes };
