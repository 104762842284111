import { useContext, useEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useQuery } from "@apollo/client";
import { GET_NOTIFICATION_HUB_TOKEN } from "graphql/queries";
import { LoggerService } from "services";
import { useDispatch } from "react-redux";
import { INotification, NotificationTypes } from "helpers";
import { AppContext } from "helpers/contexts/AppContext";
import {
  setDocumentHubUpdate,
  setNewAlertComing,
  setUnreadAlertsNumber,
  setAgreementFormStatusChange,
  setPortalReportNotification,
  setPortalPgaFormReset,
  setFarmGroupStatusChange,
  setClientId,
} from "store";
import { useHistory } from "react-router-dom";

const SignalR: React.FC = (): JSX.Element => {
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const { access_token } = useContext(AppContext);
  const { refetch: getNotificationHubToken } = useQuery(GET_NOTIFICATION_HUB_TOKEN);
  const dispatch = useDispatch();

  const history = useHistory();

  useEffect(() => {
    if (access_token) {
      const connect = new HubConnectionBuilder()
        .withUrl(`${window.REACT_APP_API_GATEWAY_URL}/notification/hub`, {
          skipNegotiation: true,
          transport: 1, //1: WebSocket, 2 :SSE, 4: LongPoll
          accessTokenFactory: async () => {
            const response = await getNotificationHubToken();
            const token = response.data?.getTokenForNotificationHub?.hubToken;

            return token;
          },
        })
        .withAutomaticReconnect()
        .build();
      setConnection(connect);
    }
  }, [getNotificationHubToken, access_token]);

  function getCookie(name:string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("NOTIFICATION", (notification: INotification) => {
            if (notification.type === NotificationTypes.UnreadAlert) {
              dispatch(setUnreadAlertsNumber(notification.data.numUnreadAlerts));

              if (notification.data.newAlert) {
                dispatch(setNewAlertComing(notification.data.newAlert));
              }
            }

            if (notification.type === NotificationTypes.AlertToDocumentHub) {
              const newUpdateDoc = Date.now();
              dispatch(setDocumentHubUpdate(newUpdateDoc));
            }

            if (notification.type === NotificationTypes.AgreementFormStatusChange) {
              const newUpdateDoc = Date.now();
              dispatch(setAgreementFormStatusChange(newUpdateDoc));
            }

            if (notification.type === NotificationTypes.ToggleMaintenanceStatus) {
              window.location.reload();
            }

            if (notification.type === NotificationTypes.ResetPasswordNotification) {
              history.push("/auth/401");
            }
            
            if (notification.type === NotificationTypes.PortalReport) {
              const newUpdateDoc = Date.now();
              dispatch(setPortalReportNotification(newUpdateDoc));
            }

            if (notification.type === NotificationTypes.PgaFormReset) {
              const newUpdateDoc = Date.now();
              dispatch(setPortalPgaFormReset(newUpdateDoc));
            }
            if (notification.type === NotificationTypes.FarmGroupStatusChange) {
              if (notification.data.farmGroupStatus) {
                dispatch(setFarmGroupStatusChange(notification.data.farmGroupStatus));
              }
            }
            if (notification.type === NotificationTypes.LoginSuccessEvent) {
              LoggerService.info("SignalR LoginSuccessEvent: " + notification.data.clientId + "-" + notification.data.timeStamp);
              if (notification.data.clientId && notification.data.timeStamp) {
                const signinTimeStamp = getCookie("signinTimeStamp");
                if(signinTimeStamp === notification.data.timeStamp)
                {
                  dispatch(setClientId(notification.data.clientId));
                }                
              }
            }
          });
        })
        .catch((error) => {
          LoggerService.error(error);
        });
    }

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [connection, dispatch, history]);
  return <></>;
};
export default SignalR;
