import { UserManagerSettings, WebStorageStateStore } from "oidc-client";

let portalUrl = window.location.origin;

export const OidcConfig: UserManagerSettings = {
  client_id: "S5E-SPA",
  redirect_uri: `${portalUrl}/auth/callback`,
  authority: "/authority",
  response_type: "id_token token",
  post_logout_redirect_uri: `${portalUrl}`,
  scope: "openid profile email graphql-gateway master-data communication personal-data reporting",
  silent_redirect_uri: `${portalUrl}/auth/silent_callback`,
  automaticSilentRenew: true,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: localStorage }),
  prompt: "login",
};
