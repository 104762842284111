import pgaFormReducer  from './pgaFormReducer';
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import producerReducer from "./producerReducer";
import dashboardReducer from "./dashboardReducer";
import remittanceReducer from "./remittanceReducer";
import performanceReducer from "./performanceReducer";
import notificationReducer from "./notificationReducer";

export default configureStore({
  reducer: {
    auth: authReducer,
    producer: producerReducer,
    dashboard: dashboardReducer,
    remittance: remittanceReducer,
    notification: notificationReducer,
    performance: performanceReducer,
    pgaForm: pgaFormReducer
  },
});
