
export const performanceYAxisSheep = ['E ', 'U ', 'R ', 'O ', 'P ', 'Total'];
export const performanceXAxisSheep = ['1', '2', '3', '3L', '3H', '4L', '4H', '5', 'Total'];
export const performanceYAxisCattle = ['E+', 'E=', 'E-', 'U+', 'U=', 'U-', 'R+', 'R=', 'R-', 'O+', 'O=', 'O-', 'P+', 'P=', 'P-', 'Total'];
export const performanceXAxisCattle = ['1-', '1=', '1+', '2-', '2=', '2+', '3-', '3=', '3+', '4-', '4=', '4+', '5-', '5=', '5+', 'Total'];
export const cattleBOAXAxisPerformance = ['1', '2', '3', '4L', '4H', '5L', '5H', 'Total'];
export const cattleBOAYAxisPerformance = ['E', 'U+', '-U', 'R', 'O+', '-O', 'P+', '-P', 'Total'];


export enum performanceSheepXAxis {
  '1 ' = 0,
  '2 ' = 1,
  '3 ' = 2,
  '3L' = 3,
  '3H' = 4,
  '4L' = 5,
  '4H' = 6,
  '5 ' = 7,
  'Total' = 8
};

export enum performanceSheepYAxis {
  'E ' = 0,
  'U ' = 1,
  'R ' = 2,
  'O ' = 3,
  'P ' = 4,
  'Total' = 5,
};

export enum performanceCattleXAxis {
  '1-' = 0, 
  '1=' = 1,
  '1+' = 2,
  '2-' = 3,
  '2=' = 4,
  '2+' = 5,
  '3-' = 6,
  '3=' = 7,
  '3+' = 8,
  '4-' = 9,
  '4=' = 10,
  '4+' = 11,
  '5-' = 12,
  '5=' = 13,
  '5+' = 14,
  'Total' = 15
};

export enum performanceCattleYAxis {
  'E+' = 0,
  'E=' = 1,
  'E-' = 2,
  'U+' = 3,
  'U=' = 4,
  'U-' = 5,
  'R+' = 6,
  'R=' = 7,
  'R-' = 8,
  'O+' = 9,
  'O=' = 10,
  'O-' = 11,
  'P+' = 12,
  'P=' = 13,
  'P-' = 14,
  'Total' = 15,
};

export const CattleBOAXAxisPerformance: any = {
  '1': 0,
  '2': 1,
  '3': 2,
  '4L': 3,
  '4H': 4,
  '5L': 5,
  '5H': 6,
  'Total': 7,
};

export const CattleBOAYAxisPerformance: any = {
  'E': 0,
  'U+': 1,
  '-U': 2,
  'R': 3,
  'O+': 4,
  '-O': 5,
  'P+': 6,
  '-P': 7,
  'Total': 8,
};

export enum WeightReportTab {
  IN_NUMBER = "in-number",
  IN_PERCENTAGE = "in-Percentage",
}

export const ReportDetailRoute = {
  1: 'report-grade',
  2: 'report-weight',
  3: 'report-mortem',
  4: 'report-gain',
  5: 'report-supply'
}

export enum ReportDetailRoute2 {
  'Weight' = 'report-weight',
  'Grade' = 'report-grade',
  'Post-mortem' = 'report-mortem',
  'Dead Weight Gain' = 'report-gain',
  'Calendar of Supply' = 'report-supply',
  'Summary' = 'report-summary',
}

export enum ReportListMode {
  templates = 'templates',
  savedReports = 'savedReports'
}

export enum PerformanceOption {
  TEMPLATE = "Template",
  SAVE_REPORT = "SaveReport",
  CREATE_REPORT = "CreateReport",
}

export enum PerformanceSites {
  BOA = "BOA"
}