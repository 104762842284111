import { createSlice } from "@reduxjs/toolkit";
import { IStore } from "helpers";

export const performanceReducer = createSlice({
  name: "performace store",
  initialState: {
    clickFromPerformanceList: false,
    summaryReport: {
      reportName: "",
      startDate: 0,
      endDate: 0,
      listSite: "",
      isSearch: false,
      listAge: "",
      listCategory: "",
      listBreed: "",
      lotIds: "",
      noLot: false,
    },
  },
  reducers: {
    setClickFromPerformanceList: (state, action) => {
      state.clickFromPerformanceList = action.payload;
    },
    setSummaryReport: (state, action) => {
      state.summaryReport = { ...state.summaryReport, ...action.payload };
    },
  },
});

export const { setClickFromPerformanceList, setSummaryReport } = performanceReducer.actions;

export const getClickFromPerformanceList = (state: IStore) =>
  state.performance.clickFromPerformanceList;

export const getSummaryReport = (state: IStore) => state.performance.summaryReport;

export default performanceReducer.reducer;
