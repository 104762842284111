import { createSlice } from "@reduxjs/toolkit";
import { IStore, UserRole } from "helpers";

export const authReducer = createSlice({
  name: "auth store",
  initialState: {
    authenticated: false,
    accessToken: "",
    buyerId: "",
    agricultureManagerId: "",
    adminId: "",
    roleTemporary: UserRole.Farmer,
    // logoutConfirmationClicked: false
  },
  reducers: {
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setTemporaryUser: (state, action) => {
      localStorage.setItem("temporaryUser", action.payload);
    },
    setAuthState: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.authenticated = true;
      state.buyerId = action.payload.buyerId;
      state.agricultureManagerId = action.payload.agricultureManagerId;
      state.adminId = action.payload.adminId;
      state.roleTemporary = action.payload.roleTemporary;
    },
  },
});

export const { setAccessToken, setTemporaryUser, setAuthState } = authReducer.actions;

export const getAuthenticated = (state: IStore) => state.auth.authenticated;
export const getAccessToken = (state: IStore) => state.auth.accessToken;
export const getTemporaryUser = (state: IStore) => {
  return localStorage.getItem("temporaryUser") || false;
};
export const getBuyerId = (state: IStore) => state.auth.buyerId;
export const getAgricultureManagerId = (state: IStore) => state.auth.agricultureManagerId;
export const getAdminId = (state: IStore) => state.auth.adminId;
export const getRoleTemporary = (state: IStore) => state.auth.roleTemporary;
export default authReducer.reducer;
