import { useLazyQuery } from "@apollo/client";
import { GET_MAINTENANCE_STATUS } from "graphql/queries";
import { AppContext } from "helpers/contexts/AppContext";
import { useContext, useEffect } from "react";

interface ICheckMaintenanceProps {
  setMaintenance: (state: boolean) => void;
  setLoadingPage: (state: boolean) => void;
}

const CheckMaintenance: React.FC<ICheckMaintenanceProps> = ({
  setMaintenance,
  setLoadingPage,
}): JSX.Element => {
  const pathName = window.location.pathname;
  const { access_token } = useContext(AppContext);

  const [getMaintenanceStatus] = useLazyQuery(GET_MAINTENANCE_STATUS, {
    onCompleted: (data) => {
      if (data.getMaintenanceStatus.responseCode === 0) {
        setMaintenance(data.getMaintenanceStatus.isUnderMaintenance);
        setLoadingPage(false);
      } else {
        setLoadingPage(false);
      }
    },
    onError: () => {
      setLoadingPage(false);
    },
  });

 useEffect(() => {
    if (pathName !== "/auth/401" && pathName !== "/auth/autologin" && access_token) {
      getMaintenanceStatus();
    } else {
      setLoadingPage(false);
    }
  }, [access_token, getMaintenanceStatus, pathName, setLoadingPage]);

  return <></>;
};

export default CheckMaintenance;
