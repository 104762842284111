export const NOT_YET_SUPPLIED = "(Not yet supplied)";
export const NOT_YET_SUPPLIED_ID = "NotYetSupplied";
export const NO_COUNTRIES = "No countries";
export const NO_SITES = "No sites";
export const NONE = "None";

export const listSpecies = [
  { id: "C", label: "Cattle" },
  { id: "S", label: "Sheep" },
];
