import { createSlice } from '@reduxjs/toolkit'
import { IStore } from 'helpers'

export const dashboardReducer = createSlice({
  name: 'dashboard store',
  initialState: {
    siteSelected: {
      siteAbb: '',
      siteId: '',
    },
    lotSelected: {
      siteAbb: '',
      siteId: '',
      lotNumber: '',
    },
    killDate: 0,
    processingLot: false
  },
  reducers: {
    setSiteSelected: (state, action) => {
      state.siteSelected = action.payload
    },
    setLotSelected: (state, action) => {
      state.lotSelected = action.payload
    },
    setKillDate: (state, action) => {
      state.killDate = action.payload
    },
    setProcessingLot: (state, action) => {
      state.processingLot = action.payload
    },
  }
})

export const {
  setSiteSelected,
  setLotSelected,
  setKillDate,
  setProcessingLot
} = dashboardReducer.actions

export const getSiteSelected = (state: IStore) => state.dashboard.siteSelected
export const getLotSelected = (state: IStore) => state.dashboard.lotSelected
export const getKillDate = (state: IStore) => state.dashboard.killDate
export const getProcessingLot = (state: IStore) => state.dashboard.processingLot

export default dashboardReducer.reducer