import { gql } from "@apollo/client";
import { DROPDOWN_LIST_PGA_FORM } from "./fragments";

export const GET_NOTIFICATION_HUB_TOKEN = gql`
  query {
    getTokenForNotificationHub {
      hubToken
    }
  }
`;

export const GET_GRADE_SUMMARY = gql`
  query getGradeSummary($getGradeSummaryInput: GetGradeSummaryInput!) {
    getGradeSummary(getGradeSummaryInput: $getGradeSummaryInput) {
      responseCode
      errorCode
      errorMessage
      gradeType {
        name
        description
      }
      gradeColors {
        name
        description
        minimumRange
        maximumRange
      }
      gradeDtos {
        conf
        fat
        value
        gradeColorName
      }
    }
  }
`;

export const GET_GRADE_PRECENTAGE_SUMMARY = gql`
  query getGradePercentageSummary($getGradePercentageSummary: GetGradePercentageSummaryInput!) {
    getGradePercentageSummary(getGradePercentageSummaryInput: $getGradePercentageSummary) {
      responseCode
      errorCode
      errorMessage
      gradeType {
        name
        description
      }
      gradeColors {
        name
        description
        minimumRange
        maximumRange
      }
      gradeDtos {
        conf
        fat
        value
        gradeColorName
      }
    }
  }
`;

export const GET_LOTS_STATUS = gql`
  query getLotsStatus($getLotsInput: GetLotsStatusRequestInput) {
    getLotsStatus(getLotsInput: $getLotsInput) {
      responseCode
      cattle
      sheep
      catteIn5Years
      sheepIn5Years
      errorCode
      errorMessage
    }
  }
`;

export const GET_LOTS_SUMMARY = gql`
  query getLotsSummary($getLotsInput: GetLotsSummaryRequestInput) {
    getLotsSummary(getLotsInput: $getLotsInput) {
      errorCode
      errorMessage
      responseCode
      totalHeadcount
      carcassValue
      bonusPayment
      lotCharges
      totalPayment
      isProcessingLot
    }
  }
`;

export const GET_PRODUCER_NAME = gql`
  query getProducerName {
    getProducerName {
      errorCode
      errorMessage
      responseCode
      fullName
      firstName
      lastName
      producerId
      profilePicUrl
    }
  }
`;

export const GET_SITE_AND_LOT = gql`
  query getSiteAndLot {
    getSiteAndLot {
      errorCode
      errorMessage
      responseCode
      introTextSheep
      introTextCattle
      hasSheepDataLast5Years
      hasCattleDataLast5Years
      siteSheep {
        siteAbb
        siteId
      }
      siteCattle {
        siteAbb
        siteId
      }
      lotNumberSheep {
        siteAbb
        siteId
        lotNumber
      }
      lotNumberCattle {
        siteAbb
        siteId
        lotNumber
      }
    }
  }
`;

export const GET_REMITTANCE = gql`
  query getRemittanceList($getRemittanceListInput: GetRemittanceListRequestInput) {
    getRemittanceList(getRemittanceListInput: $getRemittanceListInput) {
      errorCode
      errorMessage
      responseCode
      isProcessingLot
      totalPage
      totalCount
      hasDataBeyond5Years
      remittanceList {
        lotNo
        killDate
        siteId
        siteAbb
        headCount
        totalPayment
      }
    }
  }
`;

export const GET_LIST_SITE_BY_PRODUCER = gql`
  query getSiteIdByProducerId($getSiteIdByProducerIdInput: GetSiteIdByProducerIdRequestInput) {
    getSiteIdByProducerId(getSiteIdByProducerIdInput: $getSiteIdByProducerIdInput) {
      errorCode
      errorMessage
      responseCode
      siteList {
        siteId
        siteAbb
      }
    }
  }
`;

export const GET_WEIGHT_CHART = gql`
  query getWeightChart($getWeightChartsInput: GetWeightChartRequestInput) {
    getWeightChart(getWeightChartsInput: $getWeightChartsInput) {
      errorCode
      errorMessage
      responseCode
      isProcessingLot
      averageWeight
      light
      inSpecification
      heavy
    }
  }
`;

export const GET_REMITTANCE_LOT_SUMMARY = gql`
  query getRemittenceLotSummary($getRemittanceLotInput: GetRemittanceLotSummaryRequestInput) {
    getRemittanceLotSummary(getRemittanceLotInput: $getRemittanceLotInput) {
      errorCode
      errorMessage
      responseCode
      lotNumber
      totalHeadcount
      averageWeight
      totalPayment
      siteName
      currentDate
    }
  }
`;

export const GET_REMITTANCE_LOT_ANIMAL = gql`
  query getRemittanceLotAnimal($getRemLotAnimalInput: GetRemittanceLotAnimalRequestInput) {
    getRemittanceLotAnimal(getRemLotAnimalInput: $getRemLotAnimalInput) {
      errorCode
      errorMessage
      responseCode
      totalPage
      totalBonus
      totalValue
      totalCount
      totalNetWeight
      totalPayWeight
      canDisplayTotalBonus
      canDisplayAnimalBonus
      canDisplayBonusRate
      animalList {
        earTag
        category
        breed
        age
        grade
        netWeight
        condemnedWeight
        timeOnLastHolding
        payWeight
        poundPerKg
        bonus
        value
        killNo
        killDate
        dob
        conditions
        remark
        dailyDeadweightGain
        bonusRate
      }
    }
  }
`;

export const GET_REMITTANCE_LOT_CHARGES = gql`
  query getChargesOfLotList($getChargesOfLotListInput: GetChargesOfLotListRequestInput) {
    getChargesOfLotList(getChargesOfLotListInput: $getChargesOfLotListInput) {
      errorCode
      errorMessage
      responseCode
      totalCharges
      totalPage
      totalCount
      totalVat
      chargesOfLotList {
        description
        perCarcass
        qty
        charge
        vat
      }
    }
  }
`;

export const GET_LIST_OF_LOTS_BY_FILE = gql`
  query getListOfLotsByFile($input: GetListOfLotsByFileRequestInput) {
    getListOfLotsByFile(getListOfLotsByFileInput: $input) {
      errorCode
      errorMessage
      responseCode
      fileName
      listOfLotsByFile {
        site
        lot
        killDate
        carcassTotal
        bonusPayment
        totalPayment
      }
    }
  }
`;

export const GET_SIBL_LOGIN_STATUS = gql`
  query getSIBLLoginResult {
    getSIBLLoginResult {
      responseCode
      errorCode
      errorMessage
      loginResult {
        isSuccess
        guid
        message
        redirectUrl
      }
    }
  }
`;

export const GET_PORTAL_DOCUMENTLIST = gql`
  query getPortalDocumentList($input: GetPortalDocumentListRequestInput) {
    getPortalDocumentList(getPortalDocumentListInput: $input) {
      errorCode
      errorMessage
      responseCode
      totalCount
      files {
        documentId
        title
        subTitle
        tags
        createdDate
        fileName
        isPinned
      }
    }
  }
`;

export const GET_FILE_URL_REMITTANCE_LOT_ANIMAL = gql`
  query getRemittanceLotAnimal($getRemLotAnimalInput: GetRemittanceLotAnimalRequestInput) {
    getRemittanceLotAnimal(getRemLotAnimalInput: $getRemLotAnimalInput) {
      errorCode
      errorMessage
      responseCode
      fileName
    }
  }
`;

export const GET_ALERTS_FOR_PORTAL = gql`
  query getAlertsForPortal($producerId: String, $skip: Int, $pageSize: Int, $platform: String) {
    getAlertsForPortal(producerId: $producerId, platform: $platform, skip: $skip, take: $pageSize) {
      items {
        alertId
        alertTrackingId
        title
        read
        sendTimeStamp
      }
      totalCount
    }
  }
`;

export const GET_REMITTANCE_ANIMAL_DETAIL = gql`
  query getRemittanceAnimalDetails(
    $getRemittanceAnimalDetailsInput: GetRemittanceAnimalDetailsRequestInput
  ) {
    getRemittanceAnimalDetails(getRemittanceAnimalDetailsInput: $getRemittanceAnimalDetailsInput) {
      errorCode
      errorMessage
      responseCode
      additionalDetails {
        bonusDescription
        postMortem
      }
    }
  }
`;

export const GET_UNREAD_ALERTS_NUMBER = gql`
  query countUnreadAlertsNumbers($producerId: String, $platform: String) {
    countUnreadAlertsNumbers(producerId: $producerId, platform: $platform)
  }
`;

export const GET_ALERT_DETAIL = gql`
  query getAlertDetailForPortal($input: GetAlertDetailForPortalRequestInput) {
    getAlertDetailForPortal(getAlertDetailForPortalRequestInput: $input) {
      responseCode
      errorCode
      errorMessage
      title
      content
      sendDateTime
    }
  }
`;

export const GET_PREFERENCES_SETTINGS = gql`
  query getPreferencesSettings {
    getPreferencesSettings {
      responseCode
      errorCode
      errorMessage
      preferencesSettings {
        key
        isEnabled
        id
        description
      }
    }
  }
`;

export const GET_EMAIL_NOTIFICATION_STATUS = gql`
  query {
    getEmailNotificationStatus {
      isSendAlertEmail
      isSendRemittanceEmail
      isSendNewLotEmail
      responseCode
      errorCode
      errorMessage
    }
  }
`;

export const CHECK_PRODUCER_STATUS_ON_LOGIN = gql`
  query checkProducerStatusOnLogin {
    checkProducerStatusOnLogin {
      errorCode
      errorMessage
      responseCode
      isFirstLogin
    }
  }
`;

export const GET_LOT_POST_MORTEM = gql`
  query getLotPostMortem($getLotPostMortemInput: GetLotPostMortemRequestInput) {
    getLotPostMortem(getLotPostMortemInput: $getLotPostMortemInput) {
      errorCode
      errorMessage
      responseCode
      totalAffected
      potentialImpact
      conditionsFound
      conditions {
        conditionName
        affected
        hasUrl
        affectedDetails {
          killNo
          killDate
          eartag
          category
          breed
          age
          grade
          netWeight
          timeOnLastHolding
          condemnedWeight
          payWeight
          poundPerKg
          bonus
          value
          dob
          dailyDeadweightGain
          bonusRate
        }
      }
    }
  }
`;

export const CHECK_PASSWORD_STATUS = gql`
  query checkPasswordStatus {
    checkPasswordStatus {
      errorCode
      errorMessage
      responseCode
      isChangedPassword
    }
  }
`;

export const GET_GRADE_REPORT = gql`
  query getGradeReport($getGradeReportInput: GetGradeReportInput!) {
    getGradeReport(getGradeReportInput: $getGradeReportInput) {
      responseCode
      errorCode
      errorMessage
      gradeType {
        name
        description
      }
      gradeColors {
        name
        description
        minimumRange
        maximumRange
      }
      gradeDtos {
        conf
        fat
        value
        gradeColorName
      }
    }
  }
`;

export const GET_WEIGHT_REPORT = gql`
  query getWeightReport($getWeightReportInput: GetWeightReportRequestInput) {
    getWeightReport(getWeightReportInput: $getWeightReportInput) {
      errorCode
      errorMessage
      responseCode
      totalCount
      weightReportList {
        count
        percentage
      }
      categoryList {
        name
      }
      breedList {
        name
      }
    }
  }
`;

export const GET_MY_PROFILE = gql`
  query getProducerMyProfileQuery {
    getProducerMyProfile {
      responseCode
      errorCode
      errorMessage
      tradingName
      producerId
      contact
      mobile
      tel1
      emailAddress
      holdingNo
      add1
      add2
      add3
      add4
      postCode
      profilePicUrl
    }
  }
`;

export const GET_PERFORMANCE_REPORT_TYPE = gql`
  query getPerformanceReportType($input: GetPerformanceReportTypeRequestInput) {
    getPerformanceReportType(getPerformanceReportTypeInput: $input) {
      responseCode
      errorCode
      errorMessage
      reportTypes {
        id
        name
        applyForPage
        applyForSpecies
      }
    }
  }
`;

export const GET_PERFORMANCE_REPORT_LIST = gql`
  query getPerformanceReportPortal(
    $input: GetPerformanceReportRequestInput
    $skip: Int
    $pageSize: Int
  ) {
    getPerformanceReportPortal(getPerformanceReportInput: $input, skip: $skip, take: $pageSize) {
      items {
        id
        name
        reportType
        siteIds
        startDate
        endDate
      }
      totalCount
    }
  }
`;

export const GET_CATEGORY_BREED_REPORT = gql`
  query getCategoryBreedReport($getCategoryBreedReportInput: GetCategoryBreedReportInput!) {
    getCategoryBreedReport(getCategoryBreedReportInput: $getCategoryBreedReportInput) {
      responseCode
      errorCode
      errorMessage
      categoryList {
        name
      }
      breedList {
        name
      }
    }
  }
`;

export const GET_AGE_RANGE = gql`
  query getAgeRange {
    getAgeRange {
      errorCode
      errorMessage
      responseCode
      ageRangeList {
        id
        name
        start
        end
      }
    }
  }
`;

export const GET_REPORT_CALENDAR = gql`
  query getReportCalendarQuery($input: GetReportCalendarRequestInput) {
    getReportCalendar(performanceCalendarInput: $input) {
      responseCode
      errorCode
      errorMessage

      calendarData {
        year
        month
        numKillNo
      }

      categoryList {
        name
      }
      breedList {
        name
      }
    }
  }
`;

export const GET_REPORT_MORTEM = gql`
  query getReportPerformancePostMorterm($input: GetReportPerformancePostMortemRequestInput) {
    getReportPerformancePostMortem(performancePostMortemInput: $input) {
      responseCode
      errorCode
      errorMessage
      total
      numHasConditions
      conditionActiveLiverFluke
      conditionHistoricLiverFluke
      conditionPneumonia
      conditionsFound
      potentialImpact
      conditions {
        conditionName
        affected
        hasUrl
      }
      categoryList {
        name
      }
      breedList {
        name
      }
    }
  }
`;

export const GET_PM_CONDITION_ANIMAL_DETAILS = gql`
  query getPMConditionAnimalDetails($input: GetPMConditionAnimalDetailsRequestInput) {
    getPMConditionAnimalDetails(input: $input) {
      responseCode
      errorCode
      errorMessage
      affectedDetails {
        killNo
        killDate
        eartag
        category
        breed
        age
        timeOnLastHolding
        grade
        condemnedWeight
        netWeight
        payWeight
        poundPerKg
        bonus
        value
        dob
        lot
        siteId
        dailyDeadweightGain
        bonusRate
      }
    }
  }
`;

export const GET_DEAD_WEIGHT_GAIN_REPORT = gql`
  query getDeadWeightGainReport(
    $getDeadWeightGainReportInput: GetDeadWeightGainReportRequestInput
  ) {
    getDeadWeightGainReport(getDeadWeightGainReportInput: $getDeadWeightGainReportInput) {
      errorCode
      errorMessage
      responseCode
      totalCount
      deadWeightGainReportList {
        count
        percentage
      }
      categoryList {
        name
      }
      breedList {
        name
      }
    }
  }
`;
export const GET_PERFORMANCE_TEMPLATE_PORTAL = gql`
  query getPerformanceTemplatePortal(
    $input: GetPerformanceTemplateRequestInput
    $skip: Int
    $pageSize: Int
  ) {
    getPerformanceTemplatePortal(
      getPerformanceTemplateInput: $input
      skip: $skip
      take: $pageSize
    ) {
      items {
        id
        name
        templateType
        siteIds
        dateRange
        startDate
        endDate
        orderIndex
        lotIds
      }
      totalCount
    }
  }
`;

export const GET_COUNTIES = gql`
  query GetCounties {
    getCounties {
      responseCode
      errorCode
      errorMessage
      counties {
        countyId
        countyName
      }
    }
  }
`;

export const GET_COUNTRY_BY_COUNTY_ID = gql`
  query GetCountryByCountyId($countyId: String) {
    getCountryByCountyId(countyId: $countyId) {
      responseCode
      errorCode
      errorMessage
      country {
        countryId
        countryName
      }
    }
  }
`;

export const GET_DEFAULT_CONTACT_FOR_AGREEMENT_FORM = gql`
  query GetDefaultContactForAgreementForm {
    getDefaultContactForAgreementForm {
      responseCode
      errorCode
      errorMessage

      producerId
      producerName

      contractName
      contactNumber
      tradingName
      postCode

      add1
      add2
      add3
      add4

      county
      country

      alternateContactNumber
      alternateEmailAddress
    }
  }
`;

export const CHECK_ACTIVE_LINK_VALID = gql`
  query checkActiveLinkValid($checkActiveLinkValidInput: CheckActiveLinkValidInput!) {
    checkActiveLinkValid(checkActiveLinkValidInput: $checkActiveLinkValidInput) {
      errorCode
      errorMessage
      redirectPage
      responseCode
    }
  }
`;

export const GET_LOT_GRADE_SUMMARY = gql`
  query getLotGradeSummary($getLotGradeSummary: GetLotGradeSummaryInput!) {
    getLotGradeSummary(getLotGradeSummaryInput: $getLotGradeSummary) {
      responseCode
      errorCode
      errorMessage
      gradeType {
        name
        description
      }
      gradeColors {
        name
        description
        minimumRange
        maximumRange
      }
      gradeDtos {
        conf
        fat
        value
        gradeColorName
      }
    }
  }
`;

export const GET_LOT_WEIGHT_SUMMARY = gql`
  query getLotWeightSummary($getLotWeightSummary: GetLotWeightSummaryInput!) {
    getLotWeightSummary(getLotWeightSummaryInput: $getLotWeightSummary) {
      responseCode
      errorCode
      errorMessage
      weightChartDto {
        isProcessingLot
        averageWeight
        light
        inSpecification
        heavy
      }
    }
  }
`;

export const GET_GRADE_PERCENTAGE_REPORT = gql`
  query getGradePercentageReport($getGradePercentageReportInput: GetGradePercentageReportInput!) {
    getGradePercentageReport(getGradePercentageReportInput: $getGradePercentageReportInput) {
      responseCode
      errorCode
      errorMessage
      gradeType {
        name
        description
      }
      gradeColors {
        name
        description
        minimumRange
        maximumRange
      }
      gradeDtos {
        conf
        fat
        value
        gradeColorName
      }
    }
  }
`;

export const GET_REMITTANCE_VAT_REPORT = gql`
  query getRemittanceVatReport($input: GetRemittanceVatRequestInput) {
    getRemittanceVatReport(getRemittanceVatInput: $input) {
      errorCode
      errorMessage
      responseCode
      fileName
    }
  }
`;

export const GET_CATEGORIES_FOR_DOCUMENT_HUB = gql`
  query getCategoriesForDocumentHub {
    getCategoriesForDocumentHub {
      categoryId
      categoryName
      orderIndex
    }
  }
`;

export const GET_REMARKS = gql`
  query getRemarks($onlyShowToProducer: Boolean!) {
    getRemarks(onlyShowToProducer: $onlyShowToProducer) {
      remarkId
      showToProducer
      description
      species
    }
  }
`;

export const GET_REMARK_BY_ID = gql`
  query GetRemarkById($input: GetRemarkByIdRequestInput) {
    getRemarkById(getRemarkByIdInput: $input) {
      responseCode
      errorCode
      errorMessage
      remarkDto {
        remarkId
        species
        description
        showToProducer
      }
    }
  }
`;

export const GET_LAST_5_LOTS_DETAILS = gql`
  query getLast5LotsDetails($input: GetLast5LotsDetailsRequestInput) {
    getLast5LotsDetails(input: $input) {
      responseCode
      errorCode
      errorMessage
      lots {
        lot
        abb
        killDate
        shortDesc
      }
    }
  }
`;

export const GET_AVERAGE_SUMMARY_REPORT = gql`
  query getAverageSummaryReport($input: GetAverageSummaryReportInput!) {
    getAverageSummaryReport(getAverageSummaryReportInput: $input) {
      responseCode
      errorMessage
      errorCode
      averageAge
      averageWeight
    }
  }
`;

export const GET_CATEGORY_SUMMARY_REPORT = gql`
  query getCategorySummaryReport($input: GetCategorySummaryReportInput!) {
    getCategorySummaryReport(getCategorySummaryReportInput: $input) {
      responseCode
      errorMessage
      errorCode
      categoryReportDto {
        id
        name
        quantity
        percentage
      }
    }
  }
`;

export const GET_CURRENT_AGREEMENT_FORM = gql`
  query getCurrentAgreementForm(
    $getCurrentAgreementFormInput: GetCurrentAgreementFormRequestInput
  ) {
    getCurrentAgreementForm(getCurrentAgreementFormInput: $getCurrentAgreementFormInput) {
      responseCode
      errorCode
      errorMessage
      agreementFormId
      producerId
      producerName
      currentStep
      displayStep
      progressStatusId
      progressStatusName
      lastUpdatedRole

      agreementStep1Dto {
        contactName
        tradingName
        paymentAddressLine1
        addressLine2
        addressLine3
        addressLine4
        postCode
        county
        countryName
        primaryEmailAddress
        alternateEmailAddress
        mainContactNumber
        additionalContactNumber
      }
      agreementStep2Dto {
        fasMembershipNo
        farmAssuraceSchemeId
        farmAssuraceOtherValues
        primaryHoldingNumber
        additionalHoldingNumber
        organicProducer
        organicSchemeId
        organicSchemeNumber
        organicSchemeOtherValue
        environmentalScheme
        environmentalSchemeId
        environmentalSchemeOtherValue
        uploadAntibioticData
      }
      agreementStep3Dto {
        finishCattleForSlaughter
        supplyCattle
        herdPrefix
        homeBredCattle
        cattleToFinish

        cattleOutdoorsInWinter
        beddingMaterialId
        beddingMaterialOtherValue
        numbersPurchasedObject
        forecastedSupplyObject
        approveFinishingUnit
        unitGrazing
        grassDietPercentage
        feedInfoObject
        feedContainSoya
        soyaFeedingObject
        feedContainPalmOil
        palmFeedingObject
      }
      agreementStep4Dto {
        finishLambForSlaughter
        supplySheep
        flockNumber
        additionalFlockNumber
        flockSize
        eweBreedIds
        eweOther
        ramBreedIds
        ramOther
        forecastedSupplyObject
        geneticLambs
        feedInfoObject
        feedContainSoya
        soyaFeedingObject
        feedContainPalmOil
        palmFeedingObject
      }
      agreementStep5Dto {
        additionalFarmEnterprise
        farmEnterpriseIds
        farmEnterpriseOther
        farmVetPractice
        farmVetOtherName
        farmVetOtherAdd
        farmVetOtherPostCode
        farmVetOtherPhoneNo
        useAI
        companySemenSupplyIds
        companySemenSupplyOther
        companyAIServiceIds
        companyAIServiceOther
        financeForWeanedCalves
        interestedInOptions
        interestedInResearchOrProducerGroup
      }
      agreementStep6Dto {
        biodiversityIds
        bioDiversityOtherValue
        soilManagementPlan
        waterManagementPlan
        renewableEnergy
        measuredCarbonFootprint
        carbonCaculatorId
        carbonCaculatorOtherValue
        carbonFootprintCalculatedYear
        carbonFootprintResultObject
        carbonReductionPlan
        carbonReductionPlanIds
        carbonReductionPlanOtherValue
        interestedInSustainabilityProgramme
        peatExposure
        peatReductionPlan
        sellCarbonCredit
      }
      agreementStep7Dto {
        signature
      }
    }
  }
`;

export const GET_DROPDOWN_VALUES_PGA_FORM = gql`
  ${DROPDOWN_LIST_PGA_FORM}
  query getDropdownValuesPGAForm {
    OrganicSchemes: getOrganicSchemes {
      ...dropdownListPGAForm
    }
    FarmAssuraceSchemes: getFarmAssuraceSchemes {
      ...dropdownListPGAForm
    }
    EnvironmentalSchemes: getEnvironmentalSchemes {
      ...dropdownListPGAForm
    }

    BeddingMaterials: getBeddingMaterials {
      ...dropdownListPGAForm
    }
    ForecastedDates: getForecastedDates {
      ...dropdownListPGAForm
    }
    ForecastedTypesCattle: getForecastedTypesForCattle {
      ...dropdownListPGAForm
    }
    ForecastedTypesSheep: getForecastedTypesForSheep {
      ...dropdownListPGAForm
    }
    SoyaOrigins: getSoyaOrigins {
      ...dropdownListPGAForm
    }
    PalmOrigins: getPalmOrigins {
      ...dropdownListPGAForm
    }
    SheepBreeds: getSheepBreeds {
      ...dropdownListPGAForm
    }
    FarmEnterprises: getFarmEnterprises {
      ...dropdownListPGAForm
    }
    FarmVetPractices: getFarmVetPractices {
      ...dropdownListPGAForm
    }
    SemenSuppliers: getSemenSuppliers {
      ...dropdownListPGAForm
    }
    BioDiversities: getBioDiversities {
      ...dropdownListPGAForm
    }
    CarbonCalculators: getCarbonCalculators {
      ...dropdownListPGAForm
    }
    CarbonReductionPlans: getCarbonReductionPlans {
      ...dropdownListPGAForm
    }
    CarbonFootprintUnits: getCarbonFootprintUnits {
      ...dropdownListPGAForm
    }
    GetTenYearsUntilPGAFormCreatedDate: getTenYearsUntilPGAFormCreatedDate {
      ...dropdownListPGAForm
    }
    GetInterestedInOptions: getInterestedInOptions {
      ...dropdownListPGAForm
    }
  }
`;

export const GET_FORECASTED_DATES = gql`
  query getForecastedDates {
    getForecastedDates {
      id
      name
    }
  }
`;

export const GET_MAINTENANCE_STATUS = gql`
  query getMaintenanceStatus {
    getMaintenanceStatus {
      errorCode
      errorMessage
      responseCode
      isUnderMaintenance
    }
  }
`;

export const GET_PGA_FORM_EXPIRATION_STATUS = gql`
  query getPGAFormExpirationStatus {
    getPGAFormExpirationStatus {
      errorCode
      errorMessage
      isExpired
      expiredDate
      showNotification
      agreementFormId
    }
  }
`;

export const GET_LAST_YEAR_SUPPLY_DATA = gql`
  query getLastYearSupplyData($getLastYearSupplyDataInput: GetLastYearSupplyDataRequestInput) {
    getLastYearSupplyData(getLastYearSupplyDataInput: $getLastYearSupplyDataInput) {
      responseCode
      errorCode
      errorMessage
      lastYearSupplyObject
    }
  }
`;

export const GET_FILTER_VALUES_FOR_CUSTOM_VAT_REPORT = gql`
  query GetFilterValuesForCustomVatReport($input: GetFilterValuesForCustomVatReportRequestInput) {
    getFilterValuesForCustomVatReport(input: $input) {
      responseCode
      errorCode
      errorMessage
      sites {
        siteAbb
        siteId
      }
      species
    }
  }
`;

export const GET_CUSTOM_VAT_REPORTS = gql`
  query GetCustomVatReports($input: GetCustomVatReportsRequestInput) {
    getCustomVatReports(input: $input) {
      responseCode
      errorCode
      errorMessage
      totalCount
      reports {
        reportId
        reportName
        reportType
        progress
        generatedTime
        createdBy
        fileName
        startDate
        endDate
        sites
        species
        createdRole
      }
    }
  }
`;

export const CHECK_FORM_HAS_BEEN_RESET = gql`
  query CheckFormHasBeenReset($input: CheckFormHasBeenResetInput!) {
    checkFormHasBeenReset(input: $input) {
      responseCode
      errorCode
      errorMessage
      isReset
    }
  }
`;

export const GET_FORM_GROUP_REPORTS = gql`
  query GetCustomVatReports($input: GetCustomVatReportsRequestInput) {
    getCustomVatReports(input: $input) {
      responseCode
      errorCode
      errorMessage
      totalCount
      reports {
        reportId
        reportName
        reportType
        progress
        generatedTime
        createdBy
        fileName
        startDate
        endDate
        sites
        species
        createdRole
      }
    }
  }
`;

export const GET_FARM_GROUPS = gql`
  query GetFarmGroupsForPortal($input: GetFarmGroupsForPortalRequestInput) {
    getFarmGroupsForPortal(input: $input) {
      farmGroups {
        id
        name
        reportDate
      }
      totalCount
      errorCode
      errorCode
      responseCode
    }
  }
`;

export const GET_BENCHMARK_RESULTS = gql`
  query GetBenchmarkResults($input: GetBenchmarkResultsRequestInput!) {
    getBenchmarkResults(input: $input) {
      responseCode
      errorCode
      errorMessage
      benchmarkResults {
        generatedDateTime
        specIdCategory
        weightValues {
          catId
          averageTarget
          groupAverageLastTwelveMonths
          yourAverageLastTwelveMonths
          yourAverageLastKillDate
        }
        ageValues {
          catId
          averageTarget
          groupAverageLastTwelveMonths
          yourAverageLastTwelveMonths
          yourAverageLastKillDate
        }
        postMortemValues {
          catId
          averageTarget
          groupAverageLastTwelveMonths
          yourAverageLastTwelveMonths
          yourAverageLastKillDate
          children {
            catId
            condition
            averageTarget
            groupAverageLastTwelveMonths
            yourAverageLastTwelveMonths
            yourAverageLastKillDate
            actualGroupAverageLastTwelveMonths
            actualYourAverageLastTwelveMonths
            actualYourAverageLastKillDate
          }
        }
      }
    }
  }
`;

export const GET_BENCHMARK_GRADE_REPORT = gql`
  query GetBenchmarkGradeReport($input: GetBenchmarkGradeRequestInput!) {
    getBenchmarkGradeReport(input: $input) {
      gradeCharts {
        conf
        fat
        value
        gradeColorName
      }
      fatAverage
      confAverage
      showNa
      responseCode
      errorMessage
      errorCode
    }
  }
`;

export const CHECK_FARM_GROUP_STATUS = gql`
  query CheckFarmGroupStatus($input: CheckGroupStatusRequestInput!) {
    checkFarmGroupStatus(input: $input) {
      errorCode
      status
      responseCode
      errorMessage
    }
  }
`;
