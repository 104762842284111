import { createSlice } from "@reduxjs/toolkit";
import { IStore } from "helpers";

export const notificationReducer = createSlice({
  name: "notification store",
  initialState: {
    unreadAlertNumber: null,
    documentHubUpdate: 0,
    newAlert: null,
    agreementFormStatusChange: 0,
    portalReportNotification: 0,
    pgaFormResetNotification: null,
    farmGroupStatusChange: null,
    timeStamp : null,
    clientId: null
  },
  reducers: {
    setUnreadAlertsNumber: (state, action) => {
      state.unreadAlertNumber = action.payload;
    },
    setDocumentHubUpdate: (state, action) => {
      state.documentHubUpdate = action.payload;
    },
    setNewAlertComing: (state, action) => {
      state.newAlert = action.payload;
    },
    setAgreementFormStatusChange: (state, action) => {
      state.agreementFormStatusChange = action.payload;
    },
    setPortalReportNotification: (state, action) => {
      state.portalReportNotification = action.payload;
    },
    setPortalPgaFormReset: (state, action) => {
      state.pgaFormResetNotification = action.payload;
    },
    setFarmGroupStatusChange: (state, action) => {
      state.farmGroupStatusChange = action.payload;
    },
    setTimeStamp: (state, action) => {
      state.timeStamp = action.payload;
    },
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
  },
});
export const {
  setUnreadAlertsNumber,
  setDocumentHubUpdate,
  setNewAlertComing,
  setAgreementFormStatusChange,
  setPortalReportNotification,
  setPortalPgaFormReset,
  setFarmGroupStatusChange,
  setTimeStamp,
  setClientId
} = notificationReducer.actions;

export const getUnreadAlerts = (state: IStore) => state.notification.unreadAlertNumber;
export const getDocumentHubUpdate = (state: IStore) => state.notification.documentHubUpdate;
export const getNewAlertComing = (state: IStore) => state.notification.newAlert;
export const getAgreementFormStatusChange = (state: IStore) =>
  state.notification.agreementFormStatusChange;
export const getPortalReportNotification = (state: IStore) =>
  state.notification.portalReportNotification;
export const getPortalPgaFormReset = (state: IStore) =>
  state.notification.pgaFormResetNotification;
export const getFarmGroupStatusChange = (state: IStore) =>
  state.notification.farmGroupStatusChange;
export const getTimeStamp = (state: IStore) =>
  state.notification.timeStamp;
export const getClientId = (state: IStore) =>
  state.notification.clientId;

export default notificationReducer.reducer;
