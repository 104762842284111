import { createSlice } from "@reduxjs/toolkit";
import { IStore } from "helpers";

export const pgaFormReducer = createSlice({
  name: "pga form store",
  initialState: {
    isNavigated: false,
    logoutConfirmationClicked: true
  },
  reducers: {
    setIsReadyNavigated: (state, action) => {
      state.isNavigated = action.payload;
    },
    setLogoutConfirmationClicked: (state, action) => {
      state.logoutConfirmationClicked = action.payload;
    }
  },
});

export const { setIsReadyNavigated, setLogoutConfirmationClicked } = pgaFormReducer.actions;

export const getIsReadyNavigated = (state: IStore) => state.pgaForm.isNavigated;
export const getLogoutConfirmationClicked = (state: IStore) => state.pgaForm.logoutConfirmationClicked;

export default pgaFormReducer.reducer;
