import ABPLogo from "assets/images/abp-logo.svg";
import LiveStockLogo from "assets/images/live-stock-logo.svg";
import "styles/pages/error/notFound404.scss";

const NotFound404: React.FC = (): JSX.Element => {
  return (
    <div className="not-found">
      <div className="not-found__box">
        <div className="not-found__logos">
          <img src={ABPLogo} alt="abp" className="logo--abp" />
          <img src={LiveStockLogo} alt="live stock" className="logo--live-stock" />
        </div>
        <h1 className="not-found__404">404</h1>
        <h2 className="not-found__heading">PAGE NOT FOUND</h2>
        <hr className="not-found__line" />
        <p className="not-found__error">
          Sorry, the page you are looking for might be renamed, removed or might never existed
        </p>
        <a href="https://abplivestock.com" className="not-found__button" rel="noopener noreferrer">
          Back To Home
        </a>
      </div>
    </div>
  );
};

export default NotFound404;
