export const NORTHERN_IRELAND = "Northern Ireland";

export const OTHER = "Other";

export const listBooleanPGAForm = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const TABLE_ERROR_TYPE = {
  ALL: "all",
  ROW: "row",
  CELL: "cell",
  NONE: "none",
};

export const StatusPGAForm = {
  NotStarted: "NotStarted",
  DRAFT: "Draft",
  SUBMITTED: "Submitted",
  COMPLETED: "Approved",
};
