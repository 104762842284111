import React, { useContext, useEffect, useState } from "react";
import ABPLogo from "assets/icons/abp-logo.svg";
import "styles/pages/not-authen/notAuthen.scss";
import { GET_NOTIFICATION_HUB_TOKEN } from "graphql/queries";
import { useQuery } from "@apollo/client";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { INotification, NotificationTypes } from "helpers";
import { LoggerService } from "services";
import { AppContext } from "helpers/contexts/AppContext";

const Maintenance: React.FC = (): JSX.Element => {
  const [connection, setConnection] = useState<null | HubConnection>(null);
  const { access_token } = useContext(AppContext);
  const { refetch: getNotificationHubToken } = useQuery(GET_NOTIFICATION_HUB_TOKEN);

  useEffect(() => {
    if (access_token) {
      const connect = new HubConnectionBuilder()
        .withUrl(`${window.REACT_APP_GRAPHQL_URL}/gateway/signalr/notification/hub`, {
          skipNegotiation: true,
          transport: 1,
          accessTokenFactory: async () => {
            const response = await getNotificationHubToken();
            const token = response.data?.getTokenForNotificationHub?.hubToken;
            return token;
          },
        })
        .withAutomaticReconnect()
        .build();
      setConnection(connect);
    }
  }, [access_token, getNotificationHubToken]);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on("NOTIFICATION", (notification: INotification) => {
            if (notification.type === NotificationTypes.ToggleMaintenanceStatus) {
              window.location.reload();
            }
          });
        })
        .catch((error) => {
          LoggerService.error(error);
        });
    }

    return () => {
      if (connection) {
        connection.stop();
      }
    };
  }, [connection]);

  return (
    <div className="authen">
      <div className="authen__box">
        <div>
          <div className="authen__logos authen__logos--error">
            <img src={ABPLogo} alt="abp" className="logo--abp" />
          </div>
          <h1 className="authen__heading">We'll be back soon!</h1>
          <p className="authen__error" data-testid="error-message">
            ABP Hub is down for scheduled maintenance and expect to be back shortly. Please check
            again in a little while. Thank you!
          </p>
        </div>
        <div className="authen__bottom" data-testid="authen-bottom">
          <a href="https://abplivestock.com" className="authen__button" rel="noopener noreferrer">
            Back To Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
