import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";
import { routes } from "./Route";
import { authRoutes } from "./AuthRoute";
import withLayout from "helpers/hoc/withLayout";
import { AppContextProvider } from "helpers/contexts/AppContext";
import { AuthService } from "services";
import Loader from "components/Loader";
import { Provider } from "react-redux";
import store from "store/store";
import { ModeType, PATH_MODE_VARIABLE, UserRole } from "./helpers";
import NotFound404 from "./pages/error/NotFound404";
import { onError } from "@apollo/client/link/error";
import { useIdleTimer } from "react-idle-timer";
import Cookie from "components/cookie/Cookie";
import CheckMaintenance from "components/check-maintenance/CheckMaintenance";
import Maintenance from "components/check-maintenance/Maintenance";
import SignalR from "components/signal-r/SignalR";
import { ConfigProvider } from "antd";
import { StyleProvider } from "@ant-design/cssinjs";

const loading = () => <Loader />;

declare global {
  interface Window {
    REACT_APP_GRAPHQL_URL?: null;
    REACT_APP_IDENTITY_URL?: null;
    REACT_APP_CMS_URL?: null;
    REACT_APP_API_GATEWAY_URL?: null;
  }
}

axios.interceptors.request.use(async (config) => {
  const token = await AuthService.getAccessToken();
  config.headers = {
    authorization: token ? `Bearer ${token}` : "",
  };
  return config;
});

const graphQLLink = createHttpLink({
  uri: window.REACT_APP_GRAPHQL_URL + "/graphql",
});

const authLink = setContext(async (_, { headers }) => {
  const token = await AuthService.getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError((error: any) => {
  if (error.networkError?.result?.ResponseCode === 401) {
    if (error?.operation?.operationName === "getMaintenanceStatus") {
      localStorage.clear();
      window.location.href = window.REACT_APP_IDENTITY_URL;
    } else {
      AuthService.signOut();
    }
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(graphQLLink)]),
  cache: new InMemoryCache(),
});

const AuthLayout = Loadable({
  loader: () => import("components/layout/AuthLayout"),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const NonAuthLayout = Loadable({
  loader: () => import("components/layout/NonAuthLayout"),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const App = () => {
  const [maintenance, setMaintenance] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const handleOnIdle = () => {
    localStorage.clear();
    window.location.reload();
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    if (window.location.pathname === "/auth/autologin") {
      localStorage.clear();
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
          <AppContextProvider>
            <ApolloProvider client={client}>
              <ConfigProvider
                theme={{
                  token: {
                    fontFamily: "Ubuntu",
                    colorPrimary: "#0a12bf",
                    fontSize: 16,
                  },
                  components: {
                    Table: {
                      bodySortBg: "none",
                    },
                  },
                }}>
                <StyleProvider hashPriority="high">
                  <SignalR />
                  <CheckMaintenance
                    setMaintenance={setMaintenance}
                    setLoadingPage={setLoadingPage}
                  />
                  {loadingPage && <></>}
                  {!loadingPage && (
                    <>
                      {maintenance ? (
                        <Maintenance />
                      ) : (
                        <>
                          <Cookie />
                          <Switch>
                            {authRoutes.map((route, index) => {
                              return (
                                <Route
                                  key={index}
                                  path={route.path}
                                  exact={route.exact}
                                  component={withLayout((props) => {
                                    const Layout = route.useAuthLayout ? AuthLayout : NonAuthLayout;
                                    return (
                                      <Suspense fallback={loading()}>
                                        <Layout {...props} title={route.title ? route.title : ""}>
                                          <route.component {...props} />
                                        </Layout>
                                      </Suspense>
                                    );
                                  })}
                                />
                              );
                            })}
                            {routes.map((route, index) => {
                              return (
                                <Route
                                  key={index}
                                  path={`/:${PATH_MODE_VARIABLE}${route.path}`}
                                  exact={route.exact}
                                  component={withLayout((props) => {
                                    const Layout = route.useAuthLayout ? AuthLayout : NonAuthLayout;
                                    return (
                                      <Suspense fallback={loading()}>
                                        <Layout
                                          {...props}
                                          title={route.title ? route.title : ""}
                                          modeTypes={
                                            route.modeTypes ?? [ModeType.Cattle, ModeType.Sheep]
                                          }
                                          temporaryUsers={
                                            route.temporaryUsers ?? [
                                              UserRole.Admin,
                                              UserRole.AgricultureManager,
                                              UserRole.Buyer,
                                              UserRole.Farmer,
                                            ]
                                          }>
                                          <route.component {...props} />
                                        </Layout>
                                      </Suspense>
                                    );
                                  })}
                                />
                              );
                            })}
                            <Route
                              path={`/`}
                              exact={true}
                              component={withLayout((props) => {
                                const Layout = NonAuthLayout;
                                return (
                                  <Suspense fallback={loading()}>
                                    <Layout {...props} title={""}>
                                      <Redirect {...props} to="/c/dashboard" />
                                    </Layout>
                                  </Suspense>
                                );
                              })}
                            />
                            <Route
                              path={`*`}
                              exact={false}
                              component={withLayout((props) => {
                                const Layout = NonAuthLayout;
                                return (
                                  <Suspense fallback={loading()}>
                                    <Layout {...props} title={"notfound404"}>
                                      <NotFound404 {...props} />
                                    </Layout>
                                  </Suspense>
                                );
                              })}
                            />
                          </Switch>
                        </>
                      )}
                    </>
                  )}
                </StyleProvider>
              </ConfigProvider>
            </ApolloProvider>
          </AppContextProvider>
        </Provider>
      </BrowserRouter>
    </>
  );
};

export default App;
