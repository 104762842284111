export interface IRemittanceListResponse {
  errorCode: string;
  errorMessage: string;
  isProcessingLot: boolean;
  remittanceList: Array<IRemittanceItem>;
  responseCode: number;
  totalPage: number;
  totalCount: number;
  hasDataBeyond5Years: boolean;
}

export interface IRemittanceLotAnimalResponse {
  errorCode: string;
  errorMessage: string;
  responseCode: number;
  animalList: Array<IAnimalItem>;
  totalPage: number;
  totalCount: number;
  totalValue: string;
  totalNetWeight: string;
  totalPayWeight: string;
  totalBonus: string;
  canDisplayTotalBonus: boolean;
  canDisplayAnimalBonus: boolean;
  canDisplayBonusRate: boolean;
}

export interface IRemittanceDownloadFileResponse {
  errorCode: string;
  errorMessage: string;
  responseCode: number;
  fileName: string;
}

export interface IRemittanceLotChargesResponse {
  errorCode: string;
  errorMessage: string;
  responseCode: number;
  chargesOfLotList: Array<ILotChargesItem>;
  totalPage: number;
  totalCount: number;
  totalCharges: string;
  totalVat: string;
}

export interface IAnimalItem {
  earTag: string;
  category: string;
  breed: string;
  age: number;
  grade: string;
  netWeight: string;
  payWeight: string;
  poundPerKg: string;
  bonus: string;
  value: string;
}

export interface ILotChargesItem {
  description: string;
  perCarcass: string;
  qty: string;
  charge: string;
}

export enum AnimalMode {
  ANIMAL = "animal",
  LOT_CHARGES = "log-charges",
  LOT_SUMMARY = "lot-summary",
}

export interface IRemittanceItem {
  headCount: string;
  killDate: string;
  lotNo: string;
  siteAbb: string;
  totalPayment: string;
  siteId: string;
}

export interface IRemittanceLotSummaryResponse {
  errorCode: string;
  errorMessage: string;
  responseCode: number;
  lotNumber: string;
  totalHeadcount: string;
  averageWeight: string;
  totalPayment: string;
  siteName: string;
  currentDate: string;
}

export interface IDataAnimalDetail {
  bonusDescription: string;
  postMortem: Array<string>;
}

export interface IReportCreationVATErrorMsg {
  sitesMsg?: string;
  reportNameMsg?: string;
  errorMsgBottomPopup?: string;
  speciesMsg?: string;
}

export interface ICreateVATReportInput {
  startDate: number;
  endDate: number;
  site: string;
  species: string;
  name: string;
}

export interface ReportItem {
  createdBy: string;
  endDate: string;
  fileName: string;
  generatedTime: string;
  progress: string;
  reportId: string;
  reportName: string;
  reportType: number;
  sites: string;
  species: string;
  startDate: string;
  __typename: string;
}
