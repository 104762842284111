export const cattleXAxisCategories = ['1-', '1=', '1+', '2-', '2=', '2+', '3-', '3=', '3+', '4-', '4=', '4+', '5-', '5=', '5+'];
export const cattleYAxisiCategories = ['E+', 'E=', 'E-', 'U+', 'U=', 'U-', 'R+', 'R=', 'R-', 'O+', 'O=', 'O-', 'P+', 'P=', 'P-'];
export const cattleBOAXAxisCategories = ['1', '2', '3', '4L', '4H', '5L', '5H'];
export const cattleBOAYAxisCategories = ['E', 'U+', '-U', 'R', 'O+', '-O', 'P+', '-P'];

export const sheepXAxisCategoriesForLU = ['1 ', '2 ', '3 ', '4L', '4H', '5 '];
export const sheepXAxisCategoriesForOthers = ['1 ', '2 ', '3L', '3H', '4L', '4H', '5 '];
export const sheepYAxisCateories = ['E ', 'U ', 'R ', 'O ', 'P '];

export enum gradeChartColors {
  'light' = '#ccccd3',
  'light-medium' = '#9999a7',
  'dark-medium' = '#66667c',
  'dark' = '#333350'
};

export enum cattleXAxis {
  '1-' = 0,
  '1=' = 1,
  '1+' = 2,
  '2-' = 3,
  '2=' = 4,
  '2+' = 5,
  '3-' = 6,
  '3=' = 7,
  '3+' = 8,
  '4-' = 9,
  '4=' = 10,
  '4+' = 11,
  '5-' = 12,
  '5=' = 13,
  '5+' = 14,
};

export enum cattleYAxis {
  'E+' = 0,
  'E=' = 1,
  'E-' = 2,
  'U+' = 3,
  'U=' = 4,
  'U-' = 5,
  'R+' = 6,
  'R=' = 7,
  'R-' = 8,
  'O+' = 9,
  'O=' = 10,
  'O-' = 11,
  'P+' = 12,
  'P=' = 13,
  'P-' = 14,
};

export enum sheepXAxisForLU {
  '1 ' = 0,
  '2 ' = 1,
  '3 ' = 2,
  '4L' = 3,
  '4H' = 4,
  '5 ' = 5
};

export enum sheepXAxisForOthers {
  '1 ' = 0,
  '2 ' = 1,
  '3L' = 2,
  '3H' = 3,
  '4L' = 4,
  '4H' = 5,
  '5 ' = 6
};

export enum sheepYAxis {
  'E ' = 0,
  'U ' = 1,
  'R ' = 2,
  'O ' = 3,
  'P ' = 4
};

export const CattleBOAXAxis: any = {
  '1': 0,
  '2': 1,
  '3': 2,
  '4L': 3,
  '4H': 4,
  '5L': 5,
  '5H': 6,
};

export const CattleBOAYAxis: any = {
  'E': 0,
  'U+': 1,
  '-U': 2,
  'R': 3,
  'O+': 4,
  '-O': 5,
  'P+': 6,
  '-P': 7,
};

export enum GradeChartType {
  'Cattle' = 0,
  'SheepAndLU' = 1,
  'SheepAndOthers' = 2,
  'Sheep' = 3,
  'CattleBOA' = 4,
};

export const CattleWeightChartLegends = {
  light: 'Less than 260kg',
  medium: '260kg to 400kg',
  heavy: 'More than 400kg'
};

export const SheepWeightChartLegends = {
  light: 'Less than 15kg',
  medium: '15kg to 22kg',
  heavy: 'More than 22kg'
};