import { AppContext } from "helpers/contexts/AppContext";
import { useContext, useEffect } from "react";
import { LoggerService } from "services";

const Cookie: React.FC = () => {
  const { authenticated } = useContext(AppContext);

  useEffect(() => {
    if (authenticated) {
      try {
        (window as any).CookieControl.load((window as any).config);
      } catch (error) {
        LoggerService.error(error);
      }
    }
  }, [authenticated]);

  return <></>;
};

export default Cookie;
