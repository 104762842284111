import moment from "moment-timezone";
import { DATE_TIME_FORMAT, UK_TIMEZONE } from "../constants/commonData";
import { StringObjects } from "../types/commonTypes";
import * as H from "history";

export const convertDateToTimestamp = (dateString: string, isEndDate = false) => {
  const chunks = dateString.split("/");
  const dateObject = moment.tz(`${chunks[2]}-${chunks[1]}-${chunks[0]} 00:00`, UK_TIMEZONE);
  let dateTimestamp = moment(dateObject).format("x");

  if (isEndDate) {
    dateTimestamp = moment(dateObject).add(1, "days").format("x");
    const endDateTimestamp = Number(dateTimestamp) - 1000;
    return endDateTimestamp;
  }

  return Number(dateTimestamp);
};

export const convertTimestampToDate = (timestamp: number) => {
  return moment(timestamp).tz(UK_TIMEZONE).format(DATE_TIME_FORMAT);
};

export const roundDecimalNumberForChart = (org: number) => {
  const num = Math.round(org * 10000) / 10000;
  if (num === 0) return null;
  return num;
};

export const includeCommasIntoNumber = (number: string) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const saveFile = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadFileRest = (response: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const modifyQueryParams = (
  history: H.History<unknown>,
  path: string,
  query: StringObjects | URLSearchParams
) => {
  let queryStrings = "";
  if (query instanceof URLSearchParams) {
    queryStrings = query.toString();
  } else {
    queryStrings = new URLSearchParams(query).toString();
  }
  history.push({
    pathname: path,
    search: `?${queryStrings}`,
  });
};

export const renderAlertTime = (timestamp: number) => {
  const sendDate = moment(timestamp).toDate();
  const currentDate = new Date();
  const diffInMinutes = moment(currentDate).diff(sendDate, "minutes");

  if (diffInMinutes < 60) {
    return "Just Now";
  } else if (diffInMinutes >= 60 && diffInMinutes < 120) {
    return "1 hour ago";
  } else if (diffInMinutes >= 120 && diffInMinutes < 1440) {
    return `${Math.floor(diffInMinutes / 60)} hours ago`;
  } else if (diffInMinutes >= 1440 && diffInMinutes < 2880) {
    return "1 day ago";
  } else if (diffInMinutes >= 2880 && diffInMinutes < 43200) {
    return `${Math.floor(diffInMinutes / 1440)} days ago`;
  } else {
    return convertTimestampToDate(timestamp);
  }
};

export const renderNoInList = (page: number, index: number, pageSize: number) => {
  const no = (page - 1) * pageSize + index + 1;
  return no < 10 ? "0" + no : no;
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const addZeroForNumber = (num: number) => {
  return num >= 1 && num < 10 ? `0${num}` : num;
};

export const convertJsonArrayToString = (str: string) => {
  return str ? JSON.parse(str).join(", ") : "";
};

export const createRefParFromPath = (path: string) => {
  return "bc" + path?.replace("/", "_");
};

export const titleCase = (str: string) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  var splitStr2 = splitStr.join(" ").split("-");
  for (var e = 0; e < splitStr2.length; e++) {
    splitStr2[e] = splitStr2[e].charAt(0).toUpperCase() + splitStr2[e].substring(1);
  }
  return splitStr2.join("-");
};

export const stringLast5LotsToArray = (str: string) => {
  const newStr = str.replace(/"|\[|\]/g, "");
  const newArr = newStr.split(",");
  return newArr;
};

export const queryParObject = (search: string, mode: string, lotDetail: any) => {
  const query = new URLSearchParams(search);
  const bcParKey = createRefParFromPath("/remittance");
  const bcParVal = encodeURIComponent(query.toString());

  let pars: StringObjects = {
    lotNo: lotDetail.lot,
    species: mode,
    siteId: lotDetail.abb,
    killdate: lotDetail.killDate,
  };
  pars[bcParKey] = bcParVal;
  return pars;
};

export const truncateNumberToTwoDecimalWithoutRounding = (original: number | string): number => {
  const with2Decimals = original.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
  return +with2Decimals;
};

export function isEmpty(obj: object) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const convertValueToHoldingNumber = (value: string) => {
  let text = value.replace(/\D/g, "");
  if (text.length > 2) {
    text = text.replace(/.{2}/, "$&/");
  }
  if (text.length > 6) {
    text = text.replace(/.{6}/, "$&/");
  }

  return text;
};

export function add3Dots(string: string, limit: number)
{
  var dots = "...";
  if(string.length > limit)
  {
    // you can also use substr instead of substring
    string = string.substring(0,limit) + dots;
  }

    return string;
}