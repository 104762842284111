import { createSlice } from '@reduxjs/toolkit'
import { IStore } from 'helpers';

export const producerReducer = createSlice({
  name: 'producer store',
  initialState: {
    providerType: -1,
    modeSelected: '',
    providerInfo: {
      firstName: '',
      fullName: '',
      lastName: '',
      producerId: '',
      profilePicUrl: '',
    }
  },
  reducers: {
    setProviderType: (state, action) => {
      state.providerType = action.payload
    },
    setMode: (state, action) => {
      state.modeSelected = action.payload
    },
    setProviderInfo: (state, action) => {
      state.providerInfo = action.payload
    }
  }
})

export const { setProviderType, setMode, setProviderInfo } = producerReducer.actions

export const getProviderType = (state: IStore) => state.producer.providerType
export const getMode = (state: IStore) => state.producer.modeSelected
export const getProviderInfo = (state: IStore) => state.producer.providerInfo

export default producerReducer.reducer