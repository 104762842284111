import { createSlice } from "@reduxjs/toolkit";
import { FILTER_ALL, IStore } from "helpers";

export const remittanceReducer = createSlice({
  name: "remittance store",
  initialState: {
    errorMessage: "",
    isProcessingLot: false,
    list: [],
    killDate: "",
    siteName: "",
    lotSummaryFilters: {
      listAge: FILTER_ALL,
      listCategory: FILTER_ALL,
      listBreed: FILTER_ALL,
    },
    detailNA: false,
    detailAD: false,
    listSite: [],
  },
  reducers: {
    setProcessingLotRemittance: (state, action) => {
      state.isProcessingLot = action.payload;
    },
    setErrorMessageRemittance: (state, action) => {
      state.errorMessage = action.payload;
    },
    setRemittanceList: (state, action) => {
      state.list = action.payload;
    },
    setRemittanceKillDate: (state, action) => {
      state.killDate = action.payload;
    },
    setRemittanceSiteName: (state, action) => {
      state.siteName = action.payload;
    },
    setRemittanceLotSummaryFilter: (state, action) => {
      state.lotSummaryFilters = action.payload;
    },
    setRemittanceDetailNA: (state, action) => {
      state.detailNA = action.payload;
    },
    setRemittanceDetailAD: (state, action) => {
      state.detailAD = action.payload;
    },
    setRemittanceListSite: (state, action) => {
      state.listSite = action.payload;
    },
  },
});

export const {
  setProcessingLotRemittance,
  setErrorMessageRemittance,
  setRemittanceList,
  setRemittanceKillDate,
  setRemittanceSiteName,
  setRemittanceLotSummaryFilter,
  setRemittanceDetailNA,
  setRemittanceDetailAD,
  setRemittanceListSite
} = remittanceReducer.actions;

export const getProcessingLotRemittance = (state: IStore) => state.remittance.isProcessingLot;
export const getErrorMessageRemittance = (state: IStore) => state.remittance.errorMessage;
export const getRemittanceFilter = (state: IStore) => state.remittance.filter;
export const getRemittanceKillDate = (state: IStore) => state.remittance.killDate;
export const getRemittanceSiteName = (state: IStore) => state.remittance.siteName;
export const getRemittanceLotSummaryFilter = (state: IStore) => state.remittance.lotSummaryFilters;
export const getRemittanceDetailNA = (state: IStore) => state.remittance.detailNA;
export const getRemittanceDetailAD = (state: IStore) => state.remittance.detailAD;
export const getRemittanceListSite = (state: IStore) => state.remittance.listSite;
export const getRemittanceStore = (state: IStore) => state.remittance;

export default remittanceReducer.reducer;
